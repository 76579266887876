<template>
    <div class="declaration">
        <div class="header">
            <Close style="width: 20px; height: 20px; margin: 16px 16px" />
            <h3>{{ declarationTitle }}</h3>
            <MoreFilled style="width: 20px; height: 20px; margin: 16px 16px" />
        </div>

        <div class="content">
            <div class="words" v-html="declarationWords" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            declarationTitle: String,
            declarationWords: String
        };
    },
    created() {
        import(`/public/assets/texts/declaration-1.json`).then((module) => {
            this.declarationTitle = module.title
            this.declarationWords = module.words
        });
    },
    mounted() {
        const xxx = this.$cookies.get('xxx')
        console.log(xxx)
    }
}
</script>

<style lang="scss" scoped>
.declaration {
    max-width: 750px;
    min-width: 375px;
    background-color: white;

    font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
    font-size: 14px;

    .header {
        width: 100%;
        margin: 16px 0;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .content {
        padding: 16px;
        text-align: left;
    }
}
</style>