import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import axios from 'axios'
import VueCookies from 'vue-cookies'

const app = createApp(App)
for (const [name, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(name, component)
}
// axios.defaults.baseURL = 'http://testdingdan.weflywifi.com'
app.config.globalProperties.$axios = axios
app.config.globalProperties.$cookies = VueCookies
app.use(router).use(ElementPlus).mount('#app')