import { reactive } from 'vue'

export const store = reactive({
    phone: "",
    token: "",
    orderId: "",
    orderAmount: 0,
    orderDetail: {},
    invoiceInfo: {},
    isInvoiced: false,
})
