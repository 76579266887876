<template>
    <div class="order-item">
        <div style="margin: 8px 0; text-align: left; color: rgba(150, 150, 150, 1);" v-if="!alone">
            下单时间&nbsp;&nbsp;{{ createTime }}
        </div>

        <el-divider style="margin: 8px 0;" v-if="!alone" />

        <el-row :gutter="0" justify="space-between" style="margin: 8px 0;">
            <el-col :span="12" style="text-align: left; font-weight: bold;">
                {{ order.airCompany }}&nbsp;&nbsp;{{ order.flightNumber }}
            </el-col>
            <el-col :span="12" style="text-align: right; font-weight: bold; color: rgba(34, 108, 255, 1);">
                {{ orderStatusLabels[order.orderStatus] }}
            </el-col>
        </el-row>

        <el-row :gutter="0" justify="space-between" style="margin: 8px 0;">
            <el-col :span="5">
                <el-image :src="order.orderGoodsSkuDetailResps[0].skuImage" />
            </el-col>
            <el-col :span="14" style="text-align: left; padding: 8px;">
                <el-text style="font-weight: 500; color: black;">{{ order.orderGoodsSkuDetailResps[0].goodsName }}</el-text>
                <p style="margin: 0;">{{ order.orderGoodsSkuDetailResps[0].skuName }}</p>
            </el-col>
            <el-col :span="5" style="text-align: right;">
                <p style="margin: 10px 0; font-weight: bold;">{{ order.orderGoodsSkuDetailResps[0].skuAmount }}</p>
                <p style="margin: 10px 0;">{{ order.orderGoodsSkuDetailResps[0].skuNum }}</p>
            </el-col>
        </el-row>

        <el-row :gutter="0" justify="space-between"
            style="margin: 8px 0; padding: 8px; border-radius: 4px; background-color: rgba(248, 248, 248, 1);">
            <el-col :span="20" style="text-align: left;">
                <span style="font-weight: bold;">
                    {{ order.orderGoodsSkuDetailResps[0].expressCompany }}
                </span>&nbsp;&nbsp;<span>
                    {{ order.orderGoodsSkuDetailResps[0].expressNumber }}
                </span>
            </el-col>
            <el-col :span="4"
                style="color: rgba(150, 150, 150, 1); display: flex; justify-content: flex-end; align-items: center;"
                v-if="alone && order.orderGoodsSkuDetailResps[0].expressNumber.length > 0" @click="copyNumber">
                <CopyDocument style="width: 1em; height: 1em;" /><span style="margin-left: 4px;">复制</span>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import ClipboardJS from 'clipboard';

export default {
    name: "OrderItem",
    props: {
        order: {
            type: Object,
            default() {
                return {
                    orderId: "", // 订单号
                    createTime: "", // 下单时间
                    flightNumber: "", // 航班号
                    airCompany: "", // 航空公司
                    orderStatus: "", // 订单状态（0待支付 1扣款中 2待发货 3待收货 4.交易完成 5已取消）
                    orderGoodsSkuDetailResps: [
                        {
                            skuImage: "", // SKU图片
                            goodsName: "", // 商品名称
                            skuName: "", // SKU名称
                            skuAmount: "", // SKU售价
                            skuNum: "", // SKU数量
                            expressCompany: "", // 快递公司
                            expressNumber: "", // 快递单号
                        }
                    ]
                }
            }
        },
        alone: Boolean
    },
    data() {
        return {
            orderStatusLabels: {},
            createTime: ""
        }
    },
    created() {
        this.orderStatusLabels = window.g.orderStatusLabels
    },
    beforeMount() {
        const sub = this.order.createTime.split('+')[0]
        this.createTime = sub.replace('T', ' ')
    },
    methods: {
        copyNumber() {
            ClipboardJS.copy(this.order.orderGoodsSkuDetailResps[0].expressNumber)
            alert("快递单号已复制到剪贴板")
        }
    }
}
</script>

<style lang="scss" scoped>
.order-item {
    background-color: white;
    border-radius: 4px;
    padding: 4px 8px;
    color: black;
}
</style>