import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import OrderList from '../views/OrderListView.vue'
import OrderDetail from '../views/OrderDetailView.vue'
import InvoiceRequest from '../views/InvoiceRequestView.vue'
import Declaration from '../views/DeclarationView.vue'

const routes = [
    {
        path: '/',
        name: 'login',
        component: LoginView
    },
    {
        path: '/orderlist',
        name: 'orderlist',
        component: OrderList
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "about" */ '../views/OrderListView.vue')
    },
    {
        path: '/orderdetail',
        name: 'orderdetail',
        component: OrderDetail
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "about" */ '../views/OrderDetailView.vue')
    },
    {
        path: '/invoicerequest',
        name: 'invoicerequest',
        component: InvoiceRequest
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "about" */ '../views/InvoiceRequestView.vue')
    },
    {
        path: '/declaration',
        name: 'declaration',
        component: Declaration
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "about" */ '../views/DeclarationView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
