<template>
    <div class="invoice-request">
        <div style="margin: 10px;">
            <div style="width: 20px; height: 20px;" @click="gotoPrevPage">
                <ArrowLeft style="width: 100%; height: 100%" />
            </div>
        </div>

        <div class="order-info">
            <el-row :gutter="0" justify="center">
                <el-col :span="6"><span>订单编号：</span></el-col>
                <el-col :span="18"><span>{{ invoiceInfo.orderId }}</span></el-col>
            </el-row>
            <el-row :gutter="0" justify="center">
                <el-col :span="6"><span>开票金额：</span></el-col>
                <el-col :span="18"><span style="color: rgba(34, 108, 255, 1);">￥{{ orderAmount }}</span></el-col>
            </el-row>
        </div>

        <div class="invoice-info">
            <el-row :gutter="0" justify="center">
                <el-col :span="8"><span>发票类型</span></el-col>
                <el-col :span="16">
                    <el-select v-model="invoiceInfo.invoiceKind" placeholder="Select" @change="changeInvoiceKind">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </el-col>
            </el-row>
            <el-divider />
            <el-row :gutter="0" justify="center">
                <el-col :span="8"><span>抬头类型</span></el-col>
                <el-col :span="16">
                    <el-radio-group v-model="invoiceInfo.titleType" @change="changeTitleType">
                        <el-radio :label="0">个人/非企业单</el-radio>
                        <el-radio :label="1">企业</el-radio>
                    </el-radio-group>
                </el-col>
            </el-row>
            <el-divider />

            <div v-if="!isCompany">
                <el-row :gutter="0" justify="center">
                    <el-col :span="8"><span>个人名称</span></el-col>
                    <el-col :span="16"><el-input v-model="invoiceInfo.personalName" placeholder="请输入个人名称"
                            :disabled="isInvoiced" /></el-col>
                </el-row>
                <el-divider />
                <el-row :gutter="0" justify="center">
                    <el-col :span="8"><span>个人手机</span></el-col>
                    <el-col :span="16"><el-input v-model="invoiceInfo.payeePhone" placeholder="请输入个人手机"
                            :disabled="isInvoiced" /></el-col>
                </el-row>
            </div>

            <div v-if="isCompany">
                <el-row :gutter="0" justify="center">
                    <el-col :span="8"><span>发票抬头</span></el-col>
                    <el-col :span="16"><el-input v-model="invoiceInfo.companyName" placeholder="请输入购买方发票抬头"
                            :disabled="isInvoiced" /></el-col>
                </el-row>
                <el-divider />
                <el-row :gutter="0" justify="center">
                    <el-col :span="8"><span>税号</span></el-col>
                    <el-col :span="16"><el-input v-model="invoiceInfo.taxNumber" placeholder="请输入购买方发票税号"
                            :disabled="isInvoiced" /></el-col>
                </el-row>
                <el-divider />
                <el-row :gutter="0" justify="center">
                    <el-col :span="8"><span>开户银行</span></el-col>
                    <el-col :span="16"><el-input v-model="invoiceInfo.bankName" placeholder="选填"
                            :disabled="isInvoiced" /></el-col>
                </el-row>
                <el-divider />
                <el-row :gutter="0" justify="center">
                    <el-col :span="8"><span>银行账号</span></el-col>
                    <el-col :span="16"><el-input v-model="invoiceInfo.bankAccount" placeholder="选填"
                            :disabled="isInvoiced" /></el-col>
                </el-row>
                <el-divider />
                <el-row :gutter="0" justify="center">
                    <el-col :span="8"><span>企业地址</span></el-col>
                    <el-col :span="16"><el-input v-model="invoiceInfo.registerAddress" placeholder="选填"
                            :disabled="isInvoiced" /></el-col>
                </el-row>
                <el-divider />
                <el-row :gutter="0" justify="center">
                    <el-col :span="8"><span>企业电话</span></el-col>
                    <el-col :span="16"><el-input v-model="invoiceInfo.registerPhone" placeholder="选填"
                            :disabled="isInvoiced" /></el-col>
                </el-row>
                <!-- <el-divider /> -->
                <!-- <el-row :gutter="0" justify="center" v-if="!isInvoiced">
                    <el-col :span="10"><span>设置为默认抬头：</span></el-col>
                    <el-col :span="2" :offset="12">
                        <el-checkbox v-model="checked1" />
                    </el-col>
                </el-row> -->
            </div>
        </div>

        <div class="email-info">
            <el-row :gutter="0" justify="center">
                <el-col :span="8"><span>电子邮箱</span></el-col>
                <el-col :span="16"><el-input v-model="invoiceInfo.payeeEmail" placeholder="选填"
                        :disabled="isInvoiced" /></el-col>
            </el-row>
        </div>

        <div style="margin: 14px 32px;">
            <el-button type="primary" size="large" @click="popupDialogTable" v-if="!isInvoiced">提交申请</el-button>
        </div>

        <el-dialog v-model="dialogTableVisible" width="90%" :title="confirmTitle" style="background-color:whitesmoke">
            <p style="margin-bottom: 32px;">发票开局成功后不可撤销<br />请您仔细核对开票信息</p>

            <div style="background-color: white; padding: 8px 16px;">
                <el-row :gutter="0" justify="space-between">
                    <el-col :span="6"><span>{{ confirmTable[0].label }}</span></el-col>
                    <el-col :span="16"><span>{{ confirmTable[0].words }}</span></el-col>
                </el-row>
                <el-divider style="margin: 8px 0" />
                <el-row :gutter="0" justify="space-between">
                    <el-col :span="6"><span>{{ confirmTable[1].label }}</span></el-col>
                    <el-col :span="16"><span>{{ confirmTable[1].words }}</span></el-col>
                </el-row>
                <el-divider style="margin: 8px 0" />
                <el-row :gutter="0" justify="space-between">
                    <el-col :span="6"><span>{{ confirmTable[2].label }}</span></el-col>
                    <el-col :span="16"><span>￥{{ confirmTable[2].words }}</span></el-col>
                </el-row>
                <el-divider style="margin: 8px 0" />
                <el-row :gutter="0" justify="space-between">
                    <el-col :span="6"><span>{{ confirmTable[3].label }}</span></el-col>
                    <el-col :span="16"><span>{{ confirmTable[3].words }}</span></el-col>
                </el-row>
            </div>

            <el-row :gutter="0" justify="center" style="margin-top: 32px;">
                <el-col :span="12"><el-button @click="cancelInvoice">返回修改</el-button></el-col>
                <el-col :span="12"><el-button type="primary" @click="confirmInvoice">确认开具</el-button></el-col>
            </el-row>

        </el-dialog>
    </div>
</template>

<script>
import { store } from '../store/index.js'

export default {
    data() {
        return {
            store,
            isInvoiced: false,
            isCompany: false,
            orderAmount: 0,
            invoiceInfo: {
                orderId: "", // 订单号
                invoiceKind: 0, // 发票种类 0=电子普通发票 1=纸质发票
                titleType: 0, // 发票抬头类型 0=个人(只需要填个人名称、收票人手机号、邮箱) 1=企业

                personalName: "", // 个人名称
                payeePhone: "", // 收票人手机号

                companyName: "", // 单位名称
                taxNumber: "", // 发票税号
                bankName: "", // 开户银行
                bankAccount: "", // 银行账号
                registerAddress: "", // 注册地址
                registerPhone: "", // 注册电话

                payeeEmail: "", // 收票人邮箱

                // ├─ orderId	string	非必须		订单号	
                // ├─ invoiceKind	number	非必须		发票种类 0=电子普通发票 1=纸质发票	
                // ├─ titleType	number	非必须		发票抬头类型 0=个人(只需要填个人名称、收票人手机号、邮箱) 1=企业	
                // ├─ companyName	string	非必须		单位名称	
                // ├─ registerAddress	string	非必须		注册地址	
                // ├─ registerPhone	string	非必须		注册电话	
                // ├─ bankName	string	非必须		开户银行	
                // ├─ bankAccount	string	非必须		银行账号	
                // ├─ payeePhone	string	非必须		收票人手机号	
                // ├─ receiveName	string	非必须		收票人姓名	
                // ├─ payeeEmail	string	非必须		收票人邮箱	
                // ├─ personalName	string	非必须		个人名称	
                // ├─ taxNumber	string	非必须		发票税号	
                // ├─ mailAddressCode	string	非必须		邮寄地址code	
                // ├─ mailAddressDetail	string	非必须		邮寄详细地

            },

            options: [
                {
                    value: 0,
                    label: '电子普通发票',
                },
                {
                    value: 1,
                    label: '纸质发票',
                }
            ],
            optionsValue: 0,
            radio: 0,

            dialogTableVisible: false,
            confirmTitle: '开具发票',
            confirmTable: [
                {
                    label: '发票抬头',
                    words: '',
                },
                {
                    label: '发票税号',
                    words: '',
                },
                {
                    label: '发票金额',
                    words: '',
                },
                {
                    label: '电子邮箱',
                    words: '',
                }
            ]
        }
    },
    beforeMount() {
        this.isInvoiced = store.isInvoiced
        this.isCompany = (store.titleType == 1)
        this.orderAmount = store.orderAmount
        if (this.isInvoiced) {
            this.invoiceInfo = store.invoiceInfo
        }
        else {
            this.invoiceInfo.orderId = store.orderId
        }
    },
    // mounted() {
    //     console.log(window.location.href)
    //     const url = window.location.href
    //     const str = url.substring(url.lastIndexOf("/") + 1, url.length)
    //     console.log(str)
    // },
    methods: {
        gotoPrevPage() {
            this.$router.back(-1)
        },
        changeInvoiceKind(value) {
            this.invoiceInfo.invoiceKind = value
            // console.log(value)
        },
        changeTitleType(value) {
            this.invoiceInfo.titleType = value
            this.isCompany = (value == 1)
            // console.log(this.isCompany)
        },
        popupDialogTable() {
            this.dialogTableVisible = true
            if (this.isCompany) {
                this.confirmTable[0].words = this.invoiceInfo.companyName
            }
            else {
                this.confirmTable[0].words = this.invoiceInfo.personalName
            }
            this.confirmTable[1].words = this.invoiceInfo.taxNumber
            this.confirmTable[2].words = this.orderAmount
            this.confirmTable[3].words = this.invoiceInfo.payeeEmail
        },
        cancelInvoice() {
            this.dialogTableVisible = false
        },
        confirmInvoice() {
            // console.log(this.invoiceInfo)
            this.$axios({
                method: 'post',
                url: '/order/bind/invoice',
                headers: {
                    "Content-Type": "application/json",
                    "token": store.token
                },
                data: {
                    orderId: this.invoiceInfo.orderId,
                    orderInvoice: this.invoiceInfo
                }
            }).then(res => {
                console.log(res.data)
                if (res.data.code != 200) {
                    console.log(res.data.message)
                }
                else { // success
                    store.orderDetail.isInvoiced = true
                    this.$router.back(-1)
                }
            }, err => {
                console.log(err)
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.invoice-request {
    max-width: 750px;
    min-width: 375px;
    background-color: rgba(248, 248, 248, 1);

    font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
    font-size: 14px;

    .el-row .el-col span {
        width: 100%;
        display: inline-block;
        height: 32px;
        line-height: 32px;
        text-align: left;
    }

    .order-info {
        margin: 8px;
        padding: 8px 16px;
        background-color: white;
    }

    .invoice-info {
        margin: 8px;
        padding: 8px 16px;
        background-color: white;

        .el-divider {
            margin: 8px 0;
        }
    }

    .email-info {
        margin: 8px;
        padding: 16px 16px;
        background-color: white;
    }
}
</style>