<template>
    <div class="login">
        <div class="header">
            <Close style="width: 20px; height: 20px; margin: 16px 16px" />
            <p style="font-weight: bold;">{{ texts.loginTitle }}</p>
            <MoreFilled style="width: 20px; height: 20px; margin: 16px 16px" />
        </div>

        <div class="middle">
            <div style="margin-bottom: 50px">
                <h2 style="text-align: left;">{{ texts.loginLabel }}</h2>
            </div>

            <el-row :gutter="0" justify="center">
                <el-col :span="5">
                    <span style="font-weight: bold;">{{ texts.phoneNumberLabel }}</span>
                </el-col>
                <el-col :span="19">
                    <el-input v-model="phoneNumber" :placeholder="`${texts.phoneNumberPlaceholder}`" />
                </el-col>
            </el-row>
            <div class="error">
                <p v-if="phoneNumberError">{{ texts.phoneNumberErrorTips }}</p>
            </div>

            <el-divider style="margin: 6px 0 16px 0" />

            <el-row :gutter="0" justify="center">
                <el-col :span="5">
                    <span style="font-weight: bold;">{{ texts.verificationCodeLabel }}</span>
                </el-col>
                <el-col :span="11">
                    <el-input v-model="verificationCode" :placeholder="`${texts.verificationCodePlaceholder}`" />
                </el-col>
                <el-col :span="7" :offset="1">
                    <el-button type="primary" round @click="requestVerificationCode">{{ texts.requestVerificationCode
                    }}</el-button>
                </el-col>
            </el-row>
            <div class="error">
                <p v-if="verificationCodeError">{{ texts.verificationCodeErrorTips }}</p>
            </div>

            <div style="margin-top: 50px">
                <el-button type="primary" size="large" style="width: 100%; height: 100%;" @click="requestLogin">
                    {{ texts.loginButtonLabel }}
                </el-button>
            </div>

            <div style="margin-top: 20px">
                <el-checkbox v-model="policyChecked" :label="`${texts.policyAgree}`" size="large" />
                <div></div>
                <router-link target="_blank" to='../declaration'>
                    <p style="font-size: 14px;">{{ texts.policyTitle }}</p>
                </router-link>
            </div>
        </div>

        <div class="footer">
            <p>{{ texts.loginFooterWords }}</p>
        </div>
    </div>
</template>

<script>
import { store } from '../store/index.js'

export default {
    data() {
        return {
            store,
            texts: {},

            phoneNumber: "",
            verificationCode: "",
            phoneNumberError: false,
            verificationCodeError: false,

            policyChecked: false
        }
    },
    created() {
        this.texts = window.g.loginView
    },
    // mounted() {
    //     if (this.$cookies.isKey("xxx")) {
    //         this.$cookies.remove("xxx")
    //     }
    //     const xxx = {
    //         username: 'zhang',
    //         password: '12345',
    //     }
    //     this.$cookies.config("1m")
    //     this.$cookies.set("xxx", xxx)
    // },
    methods: {
        requestVerificationCode() {
            this.$axios({
                method: 'post',
                url: '/getVerificationCode',
                data: {
                    phone: this.phoneNumber
                }
            }).then(res => {
                console.log(res.data)
                if (res.data.code != 200) {
                    console.log(res.data.msg)
                    alert(res.data.msg)
                }
                else { // success
                    // 用户手机等待验证码的到来
                }
            }, err => {
                console.log(err)
                this.phoneNumberError = true
            })
        },
        requestLogin() {
            if (this.policyChecked) {
                this.$axios({
                    method: 'post',
                    url: '/phone/auth',
                    data: {
                        code: this.verificationCode
                    }
                }).then(res => {
                    console.log(res.data)
                    if (res.data.code != 200) {
                        console.log(res.data.msg)
                    }
                    else { // success
                        store.phone = res.data.data.phone
                        store.token = res.data.data.token
                        this.$router.push({ name: 'orderlist' })
                    }
                }, err => {
                    console.log(err)
                    this.verificationCodeError = true
                })
            }
            else {
                alert("请勾选表示同意" + this.texts.policyTitle)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.login {
    max-width: 750px;
    min-width: 375px;
    // height: 750px;
    background-color: white;

    font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
    font-size: 16px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .header {
        width: 100%;
        margin: 16px 0;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .middle {
        width: 90%;

        // 去掉输入框的边框
        :deep(.el-input__wrapper) {
            box-shadow: 0 0 0 0;
        }

        span {
            display: inline-block;
            height: 32px;
            line-height: 32px;
        }

        .error {
            height: 20px;
            color: red;
            font-size: 14px;
            text-align: left;
            padding: 0 6px;
            margin-top: 4px;

            p {
                margin: 0;
            }
        }
    }

    .footer {
        width: 100%;
        margin: 16px 0;

        color: rgba(174, 174, 174, 1);
        font-family: PingFangSC-Regular;
        font-size: 14px;
    }

}
</style>