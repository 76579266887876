<template>
    <div class="order-detail">
        <div style="width: 94%; margin: 10px 0; display: flex; flex-direction: row; justify-content: flex-start;">
            <div style="width: 20px; height: 20px;" @click="gotoPrevPage">
                <ArrowLeft style="width: 100%; height: 100%" />
            </div>
        </div>

        <div class="order-full">
            <order-item :order="order" alone="true"></order-item>

            <div class="order-info">
                <el-row :gutter="0" justify="center">
                    <el-col :span="6"><span>收货人：</span></el-col>
                    <el-col :span="18"><span>{{ orderAddress.toPersonName }}</span></el-col>
                </el-row>
                <el-row :gutter="0" justify="center">
                    <el-col :span="6"><span>收货电话：</span></el-col>
                    <el-col :span="18"><span>{{ orderAddress.toPhone }}</span></el-col>
                </el-row>
                <el-row :gutter="0" justify="center">
                    <el-col :span="6"><span>收货地址：</span></el-col>
                    <el-col :span="18"><span>{{ orderAddress.toAddressDetail }}</span></el-col>
                </el-row>

                <el-divider style="margin: 14px 0" />

                <el-row :gutter="0" justify="center">
                    <el-col :span="6"><span>航班信息：</span></el-col>
                    <el-col :span="18"><span>{{ order.flightNumber }}</span></el-col>
                </el-row>
                <el-row :gutter="0" justify="center">
                    <el-col :span="6"><span>在线客服：</span></el-col>
                    <el-col :span="18"><span>{{ serviceCompany }}</span></el-col>
                </el-row>
                <el-row :gutter="0" justify="center">
                    <el-col :span="6"><span>客服电话：</span></el-col>
                    <el-col :span="18"><span>{{ servicePhone }}</span></el-col>
                </el-row>
                <el-row :gutter="0" justify="center">
                    <el-col :span="6"><span>服务时间：</span></el-col>
                    <el-col :span="18"><span>{{ serviceTime }}</span></el-col>
                </el-row>

                <el-divider style="margin: 14px 0" />

                <el-row :gutter="0" justify="center">
                    <el-col :span="6"><span>订单编号：</span></el-col>
                    <el-col :span="18"><span>{{ order.orderId }}</span></el-col>
                </el-row>
                <el-row :gutter="0" justify="center">
                    <el-col :span="6"><span>下单时间：</span></el-col>
                    <el-col :span="18"><span>{{ order.createTime }}</span></el-col>
                </el-row>
                <el-row :gutter="0" justify="center">
                    <el-col :span="6"><span>支付方式：</span></el-col>
                    <el-col :span="18"><span>{{ order.payMethod }}</span></el-col>
                </el-row>
            </div>
        </div>

        <div style="margin: 14px 32px;">
            <el-button type="primary" size="large" @click="requestInvoice" v-if="!order.isInvoiced">申请发票</el-button>
            <el-button type="primary" size="large" @click="checkInvoice" v-if="order.isInvoiced">查看发票</el-button>
        </div>
    </div>
</template>

<script>
import OrderItem from '../components/OrderItem.vue'
import { store } from '../store/index.js'

export default {
    components: {
        OrderItem
    },
    data() {
        return {
            store,
            order: {
                orderId: String, // 订单号
                createTime: String, // 下单时间
                flightNumber: String, // 航班号
                orderStatus: String, // 订单状态（0待支付 1扣款中 2待发货 3待收货 4.交易完成 5已取消）
                orderGoodsSkuDetailResps: [
                    {
                        skuImage: String, // SKU图片
                        goodsName: String, // 商品名称
                        skuName: String, // SKU名称
                        skuAmount: String, // SKU售价
                        skuNum: String, // SKU数量
                        expressCompany: String, // 快递公司
                        expressNumber: String, // 快递单号
                    }
                ],
                payMethod: String, // 订单支付方式
                isInvoiced: Boolean, // 是否开过发票
            },
            orderAddress: {
                toPersonName: String, // 收货人姓名
                toPhone: String, // 收货手机号
                toAddressDetail: String, // 收货地址描述
            },
            serviceCompany: "微信公众号“飞享互联”",
            servicePhone: "4001819616",
            serviceTime: "9:00-12:00 13:00-18:00 周一至周五",
        }
    },
    beforeMount() {
        this.order.orderId = store.orderDetail.orderId

        // this.order.createTime = store.orderDetail.createTime
        const sub = store.orderDetail.createTime.split('+')[0]
        this.order.createTime = sub.replace('T', ' ')

        this.order.flightNumber = store.orderDetail.flightNumber
        this.order.orderStatus = store.orderDetail.status // 字段不同
        this.order.payMethod = store.orderDetail.payMethod
        this.order.isInvoiced = store.orderDetail.isInvoiced

        this.order.orderGoodsSkuDetailResps[0] = store.orderDetail.orderGoodsSkuDetailResps[0]
        this.orderAddress = store.orderDetail.orderAddress

        store.isInvoiced = store.orderDetail.isInvoiced
        store.orderId = store.orderDetail.orderId
        store.orderAmount = store.orderDetail.orderAmount
    },
    methods: {
        gotoPrevPage() {
            this.$router.back(-1)
        },
        requestInvoice() {
            this.$router.push({ name: 'invoicerequest' })
        },
        checkInvoice() {
            this.$axios({
                method: 'post',
                url: '/order/invoice/info',
                headers: {
                    "Content-Type": "application/json",
                    "token": store.token
                },
                data: {
                    orderId: this.order.orderId
                }
            }).then(res => {
                console.log(res.data)
                if (res.data.code != 200) {
                    console.log(res.data.msg)
                }
                else { // success
                    store.invoiceInfo = res.data.data
                    this.$router.push({ name: 'invoicerequest' })
                }
            }, err => {
                console.log(err)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.order-detail {
    max-width: 750px;
    min-width: 375px;
    background-color: rgba(248, 248, 248, 1);

    font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
    font-size: 14px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .order-full {
        width: 94%;
        background-color: white;
        border-radius: 4px;
        padding-bottom: 10px;
    }

    .order-info {
        margin: 0 32px;

        .el-row .el-col span {
            width: 100%;
            display: inline-block;
            height: 28px;
            line-height: 28px;
            text-align: left;
        }

        .el-col:first-child {
            color: rgba(174, 174, 174, 1);
        }

        .el-col:nth-child(2) {
            font-weight: bold;
        }
    }
}
</style>