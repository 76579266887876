<template>
    <!-- <nav>
        <router-link to="/">Login</router-link> |
        <router-link to="/orderlist">List</router-link> |
        <router-link to="/orderdetail">Detail</router-link> |
        <router-link to="/invoicerequest">Invoice</router-link> |
        <router-link to="/declaration">Declare</router-link>
    </nav> -->
    <router-view />
</template>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background-color: whitesmoke;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

nav {
    padding: 10px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

a {
    text-decoration: none;
}
</style>
