<template>
    <div class="order-list">
        <div class="boxes">
            <div class="box" v-for="order in orders" :key="order.orderId">
                <order-item :order="order" @click="orderDetail(order.orderId)"></order-item>
            </div>
        </div>
    </div>
</template>

<script>
import OrderItem from '../components/OrderItem.vue'
import { store } from '../store/index.js'

export default {
    components: {
        OrderItem
    },
    data() {
        return {
            store,
            orders: []
        }
    },
    beforeMount() {
        this.orderListByPhone();
    },
    methods: {
        orderListByPhone() {
            this.$axios({
                method: 'post',
                url: '/order/listByPhone',
                headers: {
                    "Content-Type": "application/json",
                    "token": store.token
                },
                data: {
                    phone: store.phone,
                    orderStatus: -1
                }
            }).then(res => {
                console.log(res.data)
                if (res.data.code != 200) {
                    console.log(res.data.msg)
                }
                else { // success
                    this.orders = res.data.rows
                }
            }, err => {
                console.log(err)
            })
        },
        orderDetail(id) {
            this.$axios({
                method: 'post',
                url: '/order/detail',
                headers: {
                    "Content-Type": "application/json",
                    "token": store.token
                },
                data: {
                    orderId: id
                }
            }).then(res => {
                console.log(res.data)
                if (res.data.code != 200) {
                    console.log(res.data.msg)
                }
                else { // success
                    store.orderDetail = res.data.data
                    this.$router.push({ name: 'orderdetail' })
                }
            }, err => {
                console.log(err)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.order-list {
    max-width: 750px;
    min-width: 375px;
    background-color: rgba(248, 248, 248, 1);

    font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
    font-size: 14px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .boxes {
        width: 94%;
        margin-top: 10px;

        .box {
            margin-bottom: 10px;
        }
    }
}
</style>